import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/layouts/ApiLayout.js";
import { graphql } from 'gatsby';
import ARIA from '../../components/AriaAbbr';
import Callout from '../../components/Callout';
import ComponentApi from '../../components/ComponentApi';
import ReactPlayground from '../../components/ReactPlayground';
import DropdownBasic from '../../examples/Dropdown/Basic';
import DropdownBasicButton from '../../examples/Dropdown/BasicButton';
import DropdownButtonCustom from '../../examples/Dropdown/ButtonCustom';
import DropdownButtonCustomMenu from '../../examples/Dropdown/ButtonCustomMenu';
import DropdownButtonSizes from '../../examples/Dropdown/ButtonSizes';
import DropDirections from '../../examples/Dropdown/DropDirections';
import DropdownItemTags from '../../examples/Dropdown/DropdownItemTags';
import MenuAlignRight from '../../examples/Dropdown/MenuAlignRight';
import MenuAlignResponsive from '../../examples/Dropdown/MenuAlignResponsive';
import MenuDividers from '../../examples/Dropdown/MenuDividers';
import MenuHeaders from '../../examples/Dropdown/MenuHeaders';
import SplitBasic from '../../examples/Dropdown/SplitBasic';
import SplitVariants from '../../examples/Dropdown/SplitVariants';
import DropdownVariants from '../../examples/Dropdown/Variants';
import styles from '../../css/examples.module.scss';
export const query = graphql`
  query DropdownMDXQuery {
    DropdownButton: componentMetadata(displayName: { eq: "DropdownButton" }) {
      displayName
      ...ComponentApi_metadata
    }
    SplitButton: componentMetadata(displayName: { eq: "SplitButton" }) {
      displayName
      ...ComponentApi_metadata
    }
    Dropdown: componentMetadata(displayName: { eq: "Dropdown" }) {
      displayName
      ...ComponentApi_metadata
    }
    DropdownToggle: componentMetadata(displayName: { eq: "DropdownToggle" }) {
      displayName
      ...ComponentApi_metadata
    }
    DropdownMenu: componentMetadata(displayName: { eq: "DropdownMenu" }) {
      displayName
      ...ComponentApi_metadata
    }
    DropdownItem: componentMetadata(displayName: { eq: "DropdownItem" }) {
      displayName
      ...ComponentApi_metadata
    }
    DropdownHeader: componentMetadata(displayName: { eq: "DropdownHeader" }) {
      displayName
      ...ComponentApi_metadata
    }
    DropdownDivider: componentMetadata(displayName: { eq: "DropdownDivider" }) {
      displayName
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "dropdowns"
    }}>{`Dropdowns`}</h1>
    <p className="lead">
  Toggle contextual overlays for displaying lists of links and more with
  the Bootstrap dropdown plugin
    </p>
    <h2 {...{
      "id": "overview"
    }}>{`Overview`}</h2>
    <p>{`Dropdowns are toggleable, contextual overlays for displaying lists of
links and more. Like overlays, Dropdowns are built using a third-party
library `}<a href="https://popper.js.org/">{`Popper.js`}</a>{`, which provides
dynamic positioning and viewport detection.`}</p>
    <h2 {...{
      "id": "accessibility"
    }}>{`Accessibility`}</h2>
    <p>{`The `}<a href="https://www.w3.org/TR/wai-aria/"><abbr title="Web Accessibility Initiative">{`WAI`}</abbr>{` `}<ARIA mdxType="ARIA" /></a>{` standard
defines a `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/wai-aria-1.1/#menu"
      }}><inlineCode parentName="a">{`role="menu"`}</inlineCode>{` widget`}</a>{`, but it's very specific to a certain kind of menu. `}<ARIA mdxType="ARIA" />{` menus, must
only contain `}<inlineCode parentName="p">{`role="menuitem"`}</inlineCode>{`, `}<inlineCode parentName="p">{`role="menuitemcheckbox"`}</inlineCode>{`, or `}<inlineCode parentName="p">{`role="menuitemradio"`}</inlineCode>{`.`}</p>
    <p>{`On the other hand, Bootstrap's dropdowns are designed to more generic
and application in a variety of situations. For this reason we don't
automatically add the menu roles to the markup. We do implement some
basic keyboard navigation, and if you do provide the "menu" role,
react-bootstrap will do its best to ensure the focus management is
compliant with the `}<ARIA mdxType="ARIA" />{` authoring guidelines for menus.`}</p>
    <h2 {...{
      "id": "examples"
    }}>{`Examples`}</h2>
    <h3 {...{
      "id": "single-button-dropdowns"
    }}>{`Single button dropdowns`}</h3>
    <p>{`The basic Dropdown is composed of a wrapping `}<inlineCode parentName="p">{`Dropdown`}</inlineCode>{` and
inner `}<inlineCode parentName="p">{`<DropdownMenu>`}</inlineCode>{`, and `}<inlineCode parentName="p">{`<DropdownToggle>`}</inlineCode>{`. By
default the `}<inlineCode parentName="p">{`<DropdownToggle>`}</inlineCode>{` will render a
`}<inlineCode parentName="p">{`Button`}</inlineCode>{` component and accepts all the same props.`}</p>
    <ReactPlayground codeText={DropdownBasic} mdxType="ReactPlayground" />
    <p>{`Since the above is such a common configuration react-bootstrap provides
the `}<inlineCode parentName="p">{`<DropdownButton>`}</inlineCode>{` component to help reduce typing. Provide
a `}<inlineCode parentName="p">{`title`}</inlineCode>{` prop and some `}<inlineCode parentName="p">{`<DropdownItem>`}</inlineCode>{`s and you're
ready to go.`}</p>
    <ReactPlayground codeText={DropdownBasicButton} mdxType="ReactPlayground" />
    <p>{`DropdownButton will forward Button props to the underlying Toggle
component`}</p>
    <ReactPlayground codeText={DropdownVariants} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "split-button-dropdowns"
    }}>{`Split button dropdowns`}</h3>
    <p>{`Similarly, You create a split dropdown by combining the Dropdown
components with another Button and a ButtonGroup.`}</p>
    <ReactPlayground codeText={SplitBasic} mdxType="ReactPlayground" />
    <p>{`As with DropdownButton, `}<inlineCode parentName="p">{`SplitButton`}</inlineCode>{` is provided as
convenience component.`}</p>
    <ReactPlayground codeText={SplitVariants} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "sizing"
    }}>{`Sizing`}</h3>
    <p>{`Dropdowns work with buttons of all sizes.`}</p>
    <ReactPlayground codeText={DropdownButtonSizes} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "drop-directions"
    }}>{`Drop directions`}</h2>
    <p>{`Trigger dropdown menus above, below, left, or to the right of their
toggle elements, with the `}<inlineCode parentName="p">{`drop`}</inlineCode>{` prop.`}</p>
    <ReactPlayground codeText={DropDirections} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "dropdown-items"
    }}>{`Dropdown items`}</h2>
    <p>{`Historically dropdown menu contents had to be links, but that’s no
longer the case with v4. Now you can optionally use
`}<inlineCode parentName="p">{`<button>`}</inlineCode>{` elements in your dropdowns instead of just `}<inlineCode parentName="p">{`<a>`}</inlineCode>{`s.`}</p>
    <p>{`You can also create non-interactive dropdown items with `}<inlineCode parentName="p">{`<Dropdown.ItemText>`}</inlineCode>{`.
Feel free to style further with custom CSS or text utilities.`}</p>
    <ReactPlayground codeText={DropdownItemTags} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "menu-alignment"
    }}>{`Menu alignment`}</h2>
    <p>{`By default, a dropdown menu is aligned to the left, but you can switch
it by passing `}<inlineCode parentName="p">{`right`}</inlineCode>{` to the `}<inlineCode parentName="p">{`align`}</inlineCode>{` prop on a `}<inlineCode parentName="p">{`<DropdownMenu>`}</inlineCode>{` or passing
`}<inlineCode parentName="p">{`right`}</inlineCode>{` to the `}<inlineCode parentName="p">{`menuAlign`}</inlineCode>{` prop on the `}<inlineCode parentName="p">{`<DropdownButton>`}</inlineCode>{` or `}<inlineCode parentName="p">{`<SplitButton>`}</inlineCode>{`
as seen below.`}</p>
    <ReactPlayground codeText={MenuAlignRight} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "responsive-alignment"
    }}>{`Responsive alignment`}</h3>
    <p>{`If you want to use responsive menu alignment, pass an object to the `}<inlineCode parentName="p">{`align`}</inlineCode>{` prop
on the `}<inlineCode parentName="p">{`<DropdownMenu>`}</inlineCode>{` or the `}<inlineCode parentName="p">{`menuAlign`}</inlineCode>{` prop on the `}<inlineCode parentName="p">{`<DropdownButton>`}</inlineCode>{` and
`}<inlineCode parentName="p">{`<SplitButton>`}</inlineCode>{`. You can specify the directions `}<inlineCode parentName="p">{`left`}</inlineCode>{` or `}<inlineCode parentName="p">{`right`}</inlineCode>{` for
the various breakpoints.`}</p>
    <Callout theme="danger" title="Warning" mdxType="Callout">
  Using responsive alignment will disable Popper usage so any dynamic positioning 
  features such as <code>flip</code> will not work.
    </Callout>
    <ReactPlayground codeText={MenuAlignResponsive} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "menu-headers"
    }}>{`Menu headers`}</h2>
    <p>{`Add a header to label sections of actions.`}</p>
    <ReactPlayground codeText={MenuHeaders} exampleClassName={styles.staticDropdownMenu} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "menu-dividers"
    }}>{`Menu dividers`}</h2>
    <p>{`Separate groups of related menu items with a divider.`}</p>
    <ReactPlayground codeText={MenuDividers} exampleClassName={styles.staticDropdownMenu} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "customization"
    }}>{`Customization`}</h2>
    <p>{`If the default handling of the dropdown menu and toggle components
aren't to your liking, you can customize them, by using the more basic
`}<inlineCode parentName="p">{`<Dropdown>`}</inlineCode>{` Component to explicitly specify the Toggle and
Menu components`}</p>
    <ReactPlayground codeText={DropdownButtonCustom} exampleClassName={styles.customDropdownMenu} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "custom-dropdown-components"
    }}>{`Custom Dropdown Components`}</h3>
    <p>{`For those that want to customize everything, you can forgo the included
Toggle and Menu components, and create your own. By providing custom
components to the `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop, you can control how each
component behaves. Custom toggle and menu components `}<strong parentName="p">{`must`}</strong>{` be able to accept refs.`}</p>
    <ReactPlayground codeText={DropdownButtonCustomMenu} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "api"
    }}>{`API`}</h2>
    <ComponentApi metadata={props.data.DropdownButton} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.SplitButton} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.Dropdown} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.DropdownToggle} exportedBy={props.data.Dropdown} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.DropdownMenu} exportedBy={props.data.Dropdown} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.DropdownItem} exportedBy={props.data.Dropdown} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.DropdownHeader} exportedBy={props.data.Dropdown} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.DropdownDivider} exportedBy={props.data.Dropdown} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      